.card {
  font-size: 16px;

  /deep/ .content {
    .main {
      width: 70%;
      margin: 0 auto;
      padding: 50px 0;
      text-align: center;

      img {
        margin-bottom: 25px;
        width: 100px;
      }

      .el-button {
        margin-top: 20px;
        min-width: 164px;
        border-radius: 23px;
        line-height: 1.5;
        font-weight: 600;
        padding: 12px 20px;

        a {
          color: #f7fafc;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .card /deep/ .content .main {
    width: 100%;
    padding: 40px 24px;
    font-size: 16px;

    img {
      width: 61px;
    }
  }
}

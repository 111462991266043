
              @import "@/assets/css/variables/variables.scss";
            

.card {
  margin: 0 auto 15px;
  background: #ffffff;
  box-shadow: $box-shadow;
  border: 0;
  line-height: 28px;

  .header {
    font-weight: bold;
    text-align: center;
  }
}
